import { useState, useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useToast } from '@abyss/web/hooks/useToast';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@abyss/web/ui/Button';
import { DateInput } from '@abyss/web/ui/DateInput';
import { DateInputRange } from '@abyss/web/ui/DateInputRange';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { useForm } from '@abyss/web/hooks/useForm';
import { processRecalc } from '../services/recalc';
import { TopMarginWrapper } from '../styles/styles';

export interface ActivityRecalculationCardProps {
    naviId: string;
};

interface FormData {
    naviId: string;
    range: boolean;
    date: string;
    dates: {
        from: string;
        to: string;
    };
};

const ActivityRecalculationCard: React.FC<ActivityRecalculationCardProps> = ({ naviId }) => {
    const auth = useAuth();
    const token = auth.user?.access_token;
    const { toast } = useToast();
    const ldClient = useLDClient();
    const showRange = ldClient?.variation('release_ss_activity_recalculation_range', true) as boolean;

    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = {
        naviId: naviId,
    };

    const form = useForm({
        defaultValues: defaultValues,
    });

    const checkedRange = form.watch('range');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onRecalc = useCallback(async (data : FormData, e : Event) => {
        setIsLoading(true);
        e.preventDefault();

        //create array of dates between start and end string
        const dates = [];
        if (data.range === true) {
            const start = new Date(data.dates.from);
            const end = new Date(data.dates.to);
            const date = new Date(start);
            while (date <= end) {
                dates.push((new Date(date)).toISOString().split('T')[0]);
                date.setDate(date.getDate() + 1);
            }
        } else {
            dates.push((new Date(data.date)).toISOString().split('T')[0]);
        }

        if(!token) {
            toast.show({
                message: 'User not authenticated',
                variant: 'error',
                autoClose: false
            });
            setIsLoading(false);
            return;
        }
        await processRecalc(token, naviId, dates, toast, setIsLoading);
    }, [naviId, token, toast]);

    return (
        <FormProvider state={form} onSubmit={onRecalc} onError={undefined}>
            {showRange &&
                <Layout.Group grow alignItems='top'>
                    <Checkbox
                        label='Range'
                        model='range'
                    />
                </Layout.Group>
            }
            <Layout.Space />
            <Layout.Group grow alignItems='top'>
                {!checkedRange ?
                    <DateInput
                        label='Date'
                        model='date'
                        isClearable
                        subText=''
                        validators={{ required: true }}
                    />
                :
                    <DateInputRange
                        model='dates'
                        subText=''
                        startDateLabel='Start Date'
                        endDateLabel='End Date'
                        hasClearButton='true'
                        validators={{ required: true }}
                    />
                }
            </Layout.Group>
            <TopMarginWrapper>
                <Button type='submit' isLoading={isLoading} ariaLoadingLabel='Recalculating' >
                    Recalculate
                </Button>
            </TopMarginWrapper>
        </FormProvider>
    );
};

export default ActivityRecalculationCard;
