import { FC } from 'react';
import styled from 'styled-components';
import { Modal } from '@abyss/web/ui/Modal';
import { Link } from '@abyss/web/ui/Link';

export interface HelpModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const GithubTokenModal: FC<HelpModalProps> = ({ isOpen, setIsOpen }) => {

    return (
        <Modal title='GitHub Token Information' isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Section>
                    <p>
                        It is strongly suggested to create a new GitHub PAT specifically for Sheikah Slate because it will be stored in the cookies of your browser and not asked for again. Using a token specific to this app with only the specified necessary permissions will keep our code as secure as possible.
                    </p>
            </Modal.Section>
            <Modal.Section>
                    <h3>To generate a GitHub Token follow these steps:</h3>
            </Modal.Section>
            <Modal.Section>
                    <ol style={{ listStyleType: 'decimal', marginLeft: '2rem' }}>
                        <li>Navigate to <Link href="https://github.com/settings/tokens" openNewWindow>https://github.com/settings/tokens</Link></li>
                        <li>Click &quot;Generate new token&quot;, then &quot;Generate new token (classic)&quot;</li>
                        <li>Give it a new name (such as &quot;shiekah-slate&quot;) and select the following permissions:
                            <ul>
                                <li><strong>repo</strong> (top level/all)</li>
                                <li>admin:org
                                    <MarginList>
                                        <li><strong>read:org</strong> (only this one)</li>
                                    </MarginList>
                                </li>
                                <li>write:discussion
                                    <MarginList>
                                        <li><strong>read:discussion</strong> (only this one)</li>
                                    </MarginList>      
                                </li>
                                <li>admin:enterprise
                                    <MarginList>
                                        <li><strong>read:enterprise</strong> (only this one)</li>
                                    </MarginList>  
                                </li>
                            </ul>
                        </li>
                        <li>Copy the token value as it is the only time it will ever be visible.</li>
                        <li>Click &quot;Configure SSO&quot; next to your new token and select the necessary organizations. Specifically &quot;uhg-internal&quot; and likely &quot;optum-digital-rewards&quot;. You might have to go through the SSO process after selecting each of these.</li>
                    </ol>
            </Modal.Section>
        </Modal>
    );
};

const MarginList = styled.ul`
    margin-left: 2rem;
`;

export default GithubTokenModal;
