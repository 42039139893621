/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { Button } from '@abyss/web/ui/Button';
import { TextInput } from '@abyss/web/ui/TextInput';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { TopMarginWrapper } from '../styles/styles';
import GithubTokenModal from './GithubTokenModal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function TokenInput({ setToken, setLoading }: any) {
    const [value, setValue] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = () => {
        setIsModalOpen(true);
      };

    const onSubmit = () => {
        setToken(value);
        Cookies.set('githubToken', value);
        setLoading(true);
    };

    return (
        <React.Fragment>
                <GithubTokenModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
                <TextInput
                    placeholder = 'ghp_' 
                    value = {value} 
                    isClearable 
                    onChange = {(e: any) => setValue(e.target.value)} 
                    onClear = {() => setValue('')}
                    label = 'GitHub Token'
                    helper ={
                                <IconSymbol
                                    icon = "help"
                                    variant = "outlined"
                                    onClick = {handleClick}
                                    size = '$sm'
                                    style = {{ cursor: 'pointer' }}
                                />
                            }
                />
                <TopMarginWrapper>
                    <Button onClick = {onSubmit}>
                        Submit
                    </Button>
                </TopMarginWrapper>
        </React.Fragment>
    );
}
